import React, { useRef, useState, useCallback, useLayoutEffect, useEffect } from 'react'


const InfiniteScrollLoop = ({ children }) => {
  const contentRef = useRef(null);
  const scrollRef = useRef(null);
  const animationRef = useRef();
  const timerRef = useRef();
  const [height, setHeight] = useState(0);

  const randomIntFromInterval = (min, max) => (
    Math.floor(Math.random() * (max - min + 1) + min)
  )

  const handleScroll = useCallback(() => {
    if (scrollRef.current) {
      const scroll = scrollRef.current.scrollTop;
      if (scroll < height || scroll >= height + height) {
        scrollRef.current.scrollTop = height + (scroll % height);
      }
    }
  }, [height]);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.offsetHeight);
      // start at random scroll position
      scrollRef.current.scrollTop = randomIntFromInterval(0, height);
    }
  });
  
  useEffect(() => {
    startAnimation();
    // CLEANUP
    return () => {
      if (animationRef.current) clearInterval(animationRef.current);
      if (timerRef.current) clearTimeout(timerRef.current);
    }
  }, [])

  const startAnimation = () => {
    animationRef.current = setInterval(() => {
      scrollRef.current.scrollTop += 1;
    }, 15)
  }

  const stopAnimation = () => {
    clearInterval(animationRef.current);
  }

  const animationTimeout = () => {
    stopAnimation();

    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      startAnimation();
      // CLEANUP
      clearTimeout(timerRef.current);
    }, 1000)
  }

  return (
    <div className="infinite-scroll-loop-outer">
      <div className="fade-overlay top"></div>
      <div className="fade-overlay bottom"></div>
      <div
        className="infinite-scroll-loop-inner"
        ref={scrollRef}
        onScroll={handleScroll}
        onWheel={animationTimeout}
        onTouchMove={animationTimeout}
      >
        <div>{children}</div>
        <div ref={contentRef}>{children}</div>
        <div>{children}</div>
      </div>
    </div>
  );
}

export default InfiniteScrollLoop;