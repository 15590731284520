import React from "react";
import { navigate } from 'gatsby-link';
import { Link } from "gatsby";
import NameList from "../components/NameList";
import Layout from "../components/Layout";
import Zoom from 'react-reveal/Zoom';
import CircleButton from "../components/CircleButton";

export const List = ({ searchInput="", hideSearchButton=false }) => {
  if (typeof window === 'undefined') { return null }

  return (
    <Layout autoResetOff>
      <Zoom>
        <>
          <div className="startpage-nav-container">
            <Link to={"/comment"} className="brand">tell their stories</Link>
            <Link to={"/about"} className="about">about</Link>
          </div>
          <div className="name-list-container">
            <NameList searchInput={searchInput} hideSearchButton={hideSearchButton} />
          </div>
          <CircleButton bottom={1} right={5} type={"plus"} color={"white"} onClick={() => navigate("/comment")}/>
        </>
      </Zoom>
    </Layout>
  );
};

export default List;
