import React, { useState, useRef, useEffect } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import InfiniteScroll from "./InfiniteScroll";
import CircleButton from "./CircleButton";
import Keyboard from './Keyboard';
import 'react-simple-keyboard/build/css/index.css';


const NameList = ({ searchInput='', hideSearchButton=false }) => {
  const data = useStaticQuery(graphql`
    query NameListQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "biography" }, hidden: { ne: true } } }
        sort: { fields: [frontmatter___infoDeath___deathYear], order: ASC }
      ) {
        edges {
          node {
            excerpt(pruneLength: 400)
            id
            fields {
              slug
            }
            frontmatter {
              name
              hidden
              infoDeath {
                deathYear
                deathCity
                deathDistrict
              }
              objects {
                objectName
                width
              }
              templateKey
            }
          }
        }
      }
    }
  `)

  const searchInputRef = useRef();

  const allBiographies = data.allMarkdownRemark.edges;

  const [biographies, setBiographies] = useState(allBiographies);
  const [input, setInput] = useState(searchInput);
  // use random number for this state
  // to trigger useEffect hook in keyboard component
  const [keyboardWakeUp, setKeyboardWakeUp] = useState(0);

  useEffect(() => {
    const filteredData = allBiographies.filter(edge => {
      const { name, infoDeath } = edge.node.frontmatter;
      if (
        name.toLowerCase().includes(input.toLowerCase()) ||
        (infoDeath && infoDeath.deathYear && infoDeath.deathYear.toString().includes(input)) ||
        (infoDeath && infoDeath.deathCity && infoDeath.deathCity.toLowerCase().includes(input.toLowerCase())) ||
        (infoDeath && infoDeath.deathDistrict && infoDeath.deathDistrict.toLowerCase().includes(input.toLowerCase()))
      ) {
        return true 
      }
    })
    setBiographies(filteredData);
  }, [input])

  const showFilterInput = () => {
    setKeyboardWakeUp(Math.random());

    // do this here and not in CSS due to touch compatibility
    const button = document.getElementById("filter-button");
    button.style.display = "none";

    const input = document.getElementById("filter-input");
    input.style.display = "block";

    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }

  const handleInputChange = (event) => {
    setInput(event.target.value);
  }

  const hasPlaceholderContent = (post) => (
    post.frontmatter.objects.some((object) => {
      return object.objectName && object.objectName.includes("im Aufbau");
    })
  )

  return (
    <div>
      {!hideSearchButton && <div className="filter-button">
        <Keyboard setInput={setInput} currentValue={input} wakeUp={keyboardWakeUp} autoHide />

        <CircleButton
          id="filter-button"
          top={1} right={1} type={"search"} color={"white"}
          noCircle
          onTouchEnd={showFilterInput}
          onClick={showFilterInput}
        />

        <input id="filter-input" type="text" aria-label="Search"
          autoComplete="off"
          placeholder="name / jahr / ort / land"
          value={input}
          onChange={handleInputChange}
          onClick={() => setKeyboardWakeUp(Math.random())}
          onTouchStart={() => setKeyboardWakeUp(Math.random())}
          onFocus={() => setKeyboardWakeUp(Math.random())}
        />
      </div>}

      <InfiniteScroll>
        {biographies && biographies.map(({ node: post }) => (
          <Link
            key={post.frontmatter.name}
            to={post.frontmatter.objects ? post.fields.slug : null}
            className={`
              name-list-item
              ${post.frontmatter.objects ? "with-content" : ""}
              ${hasPlaceholderContent(post) ? "" : "highlighted"}
              count_${biographies.length}
            `}
          >
            {post.frontmatter.name}
          </Link>
        ))}
        {/* Spacing at end of list to indicate the start: */}
        {biographies.length > 2 && <div style={{paddingTop: "30vh"}}></div>}
      </InfiniteScroll>
    </div>
  )
};

export default NameList;
